import React from "react";
import building from "../assets/building.jpg";
import "../styles/About.css";
function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${building})` }}
      ></div>
      <div className="aboutBottom">
        <div className="label">
        <h1> SERVING THE GREATER DAYTON AREA</h1>
        </div>
        <div>
        <p>
          Since 1999, Berry Roofing Company has worked hard to put a roof over your head and ours. Berry Roofing Co. is licensed and insured, and 
          will meet all customer needs. We strive to provide efficient and honest service, and are Professional & Non Smoking. 
        </p>
        </div>
      </div>
    </div>
  );
}

export default About;
