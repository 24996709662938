import Asphalt from '../assets/asphalt.jpg';
import Inspection from '../assets/inspection.jpg';
export const MenuList = [
  {
    name: "Asphalt Shingles",
    image: Asphalt,
    price: "3 Tab Asphalt Shingles"
  },
  {
    name: "Inspections",
    image: Inspection,
    price: "Independent 3rd party inspections."
  }
];
