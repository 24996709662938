import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Link } from "react-router-dom";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
      <a href="https://www.facebook.com/Berry-Roofing-Co-147837168617196">
         <FacebookIcon /> 
        </a>
      </div>
      <p> &copy; 2021 Brad Carter</p>
    </div>
  );
}

export default Footer;