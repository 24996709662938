import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/roofs.jpg";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
      <div className="headerContainer">
        <div className="nameContainer">
          <div className="test">
          <h1> Berry Roofing Co. </h1>
          </div>
          <div>
          <p> LICENSED & INSURED</p>
          </div>
        </div>
        
        <Link to="/contact">
          <button> Get a free estimate </button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
